section#home {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/background-waves.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.home-container {
  max-width: 1440px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-text-container {
  margin: 2rem 0;
}

.hero-superscript {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.hero-text {
  color: white;
  font-family: "Poppins", sans-serif;
}

.hero-text h1 {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 2rem;
}

.hero-text #name {
  background: rgb(255, 74, 74);
  background: linear-gradient(160deg, #ff4a4a 0%, #ffd339 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subscript {
  color: #d1d1d1;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.25rem;
  display: block;
  margin-bottom: 1rem;
  min-height: 130px;
}

.hero-subscript .role {
  background-color: black;
  color: #d7ab4c;
}

.hero-subscript #years {
  font-weight: bold;
}

.btn-container {
  display: flex;
  align-items: center;
}

.btn-container button {
  border: 0;
}

#btn-download {
  border-radius: 5px 0 0 5px;
}

#btn-download:hover {
  background-color: black;
  color: white;
}

#btn-new-tab {
  border-radius: 0 5px 5px 0;
  background-color: black;
  color: white;
}

#btn-new-tab:hover {
  background-color: white;
  color: black;
}

.hero-svg-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-svg-relative-container {
  min-width: 50%;
  position: relative;
  height: 256px;
  width: 100%;
}

.hero-svg-relative-container svg {
  position: absolute;
  transform: translate(-50%, -50%);
}

svg#css-logo {
  height: 116px;
  width: 116px;
  left: 57%;
  top: 14%;
}

svg#html-logo {
  height: 132.5px;
  width: 132.5px;
  top: 32%;
  left: 30%;
}

svg#js-logo {
  height: 189px;
  width: 189px;
  top: 73%;
  left: 24%;
}

svg#node-logo {
  height: 140.5px;
  width: 140.5px;
  top: 71%;
  left: 84%;
}

svg#ts-logo {
  height: 141.5px;
  width: 141.5px;
  top: 35%;
  left: 85%;
}

svg#next-logo {
  height: 156.6px;
  width: 156.5px;
  top: 96%;
  left: 57%;
}

svg#react-logo {
  height: 191px;
  width: 191px;
  left: 58%;
  top: 52%;
}

.pdf-download-link {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) {
  .hero-superscript {
    font-size: 2rem;
  }

  .hero-text h1 {
    font-size: 4.25rem;
    margin-bottom: 1rem;
  }

  .hero-subscript {
    min-height: 0;
    margin-bottom: 2rem;
    width: 70%;
  }

  svg#css-logo {
    height: 139px;
    width: 139px;
    left: 66%;
    top: 0%;
  }

  svg#html-logo {
    height: 159px;
    width: 159px;
    top: 22%;
    left: 52%;
  }

  svg#js-logo {
    height: 226.8px;
    width: 226.8px;
    top: 72%;
    left: 49%;
  }

  svg#node-logo {
    height: 168.6px;
    width: 168.6px;
    top: 64%;
    left: 85%;
  }

  svg#ts-logo {
    height: 169.8px;
    width: 169.8px;
    top: 18%;
    left: 83%;
  }

  svg#next-logo {
    height: 187.8px;
    width: 187.8px;
    top: 101%;
    left: 68%;
  }

  svg#react-logo {
    height: 229.2px;
    width: 229.2px;
    left: 68%;
    top: 48%;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  .home-container {
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 3rem;
  }

  .hero-text-container,
  .hero-svg-container {
    margin: 0;
    flex: 1 1 50%;
    min-width: 50%;
  }

  .hero-superscript {
    font-size: 2.25rem;
  }

  .hero-text h1 {
    font-size: 5.25rem;
    margin-bottom: 2rem;
  }

  .hero-subscript {
    font-size: 2rem;
    width: 100%;
  }

  .hero-svg-relative-container {
    height: 32rem;
  }

  svg#css-logo {
    height: 232px;
    width: 232px;
    left: 54%;
    top: 11%;
  }

  svg#html-logo {
    height: 265px;
    width: 265px;
    top: 28%;
    left: 32%;
  }

  svg#js-logo {
    height: 378px;
    width: 378px;
    top: 76%;
    left: 27%;
  }

  svg#node-logo {
    height: 281px;
    width: 281px;
    top: 65%;
    left: 86%;
  }

  svg#ts-logo {
    height: 283px;
    width: 283px;
    top: 27%;
    left: 82%;
  }

  svg#next-logo {
    height: 313px;
    width: 313px;
    top: 93%;
    left: 66%;
  }

  svg#react-logo {
    height: 382px;
    width: 382px;
    left: 57%;
    top: 50%;
  }
}
