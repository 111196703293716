.btn {
  all: unset;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  padding: 0.25rem 1.5rem;
  margin: 0;
  height: 2.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.25rem;
  border: 1px solid black;
  font-weight: bold;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
}
