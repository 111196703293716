section#contact {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/background-waves-end.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 2rem;
  gap: 1rem;
}

.contact-text {
  color: white;
  text-align: end;
}

.contact-text h2 {
  font-size: 2.5rem;
}

.contact-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
  .contact-text {
    text-align: start;
  }
  .contact-info-container {
    flex-direction: row;
    justify-content: center;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  .contact-text h2 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
}
