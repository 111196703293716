.cursor {
  position: absolute;
  left: 0;
  top: 0;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.cursor::after,
.cursor::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50px;
}

/*.cursor::before{
  background-color: red;
}*/

.cursor.click::before {
  animation: click 0.4s ease-in-out forwards;
}

@keyframes click {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    background-color: aqua;
  }
  99% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(5);
    background-color: aqua;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(5);
    background-color: white;
  }
}
