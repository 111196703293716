html,
body {
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Media Queries */

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) {
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) {
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
}
