.chip {
  box-sizing: border-box;
  background-color: black;
  color: white;
  border-radius: 1rem;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  font-family: "Nunito Sans", sans-serif;
}

.chip > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
}
