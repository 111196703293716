section#experience {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f3f3;
}

.experience-container {
  box-sizing: border-box;
  max-width: 1440px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.experience-title h1 {
  background: rgb(255, 74, 74);
  background: linear-gradient(
    160deg,
    rgba(255, 74, 74, 1) 0%,
    rgba(255, 211, 57, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 2.5rem;
}

.experience-subtitle p {
  margin: 0;
  color: #3a3a3a;
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  .experience-container {
    padding: 2rem 0;
  }

  .experience-title h1 {
    font-size: 4rem;
  }
}
